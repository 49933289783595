<template>
  <div>
    <div class="btn-group mb-1">
      <button
        class="btn btn-light d-flex"
        disabled
      >
        <FeatherIcon
          icon="GlobeIcon"
        />
        <span>ES</span>
      </button>
      <a
        :href="getUrl(model.getAttr('url', false))"
        class="btn btn-primary"
        target="_blank"
      >{{ model.getAttr('url', false) }}</a>
    </div>

    <div class="btn-group mb-1">
      <button
        class="btn btn-light d-flex"
        disabled
      >
        <FeatherIcon
          icon="GlobeIcon"
        />
        <span>EN</span>
      </button>
      <a
        :href="getUrl(model.getAttr('url_en', false))"
        class="btn btn-primary"
        target="_blank"
      >{{ model.getAttr('url_en', false) }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UrlSelectComponent',
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    getUrl(path) {
      return `https://irenebuffa.com${path}`
    },
  },
}
</script>

<style scoped>

</style>
