import Vue from 'vue'
import VueRouter from 'vue-router'
import { getHomeRouteForLoggedInUser, isUserLoggedIn } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },
    {
      path: '/users',
      name: 'usersView',
      component: () => import('@/views/modules/UsersView.vue'),
      meta: {
        pageTitle: 'Usuarios',
        breadcrumb: [
          {
            text: 'Usuarios',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },
    {
      path: '/carritos',
      name: 'sessionsView',
      component: () => import('@/views/modules/SessionsView.vue'),
      meta: {
        pageTitle: 'Carritos Abandonados',
        breadcrumb: [
          {
            text: 'Carritos Abandonados',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },

    {
      path: '/commissions',
      name: 'commissionsView',
      component: () => import('@/views/modules/CommissionsView.vue'),
      meta: {
        pageTitle: 'Comisiones',
        breadcrumb: [
          {
            text: 'Comisiones',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },

    {
      path: '/brands',
      name: 'brandsView',
      component: () => import('@/views/modules/BrandsView.vue'),
      meta: {
        pageTitle: 'Marcas',
        breadcrumb: [
          {
            text: 'Marcas',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },

    {
      path: '/banners',
      name: 'bannersView',
      component: () => import('@/views/modules/BannersView.vue'),
      meta: {
        pageTitle: 'Banners',
        breadcrumb: [
          {
            text: 'Banners',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },

    {
      path: '/customers',
      name: 'customersView',
      component: () => import('@/views/modules/CustomersView.vue'),
      meta: {
        pageTitle: 'Clientes',
        breadcrumb: [
          {
            text: 'Clientes',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },

    {
      path: '/frequent-questions',
      name: 'frequentQuestionsView',
      component: () => import('@/views/modules/FrequentQuestionsView.vue'),
      meta: {
        pageTitle: 'Preguntas Frecuentes',
        breadcrumb: [
          {
            text: 'Preguntas Frecuentes',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },

    {
      path: '/cities',
      name: 'citiesView',
      component: () => import('@/views/modules/CitiesView.vue'),
      meta: {
        pageTitle: 'Ciudades',
        breadcrumb: [
          {
            text: 'Ciudades',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },

    {
      path: '/configurations',
      name: 'configurationsView',
      component: () => import('@/views/modules/ConfigurationsView.vue'),
      meta: {
        pageTitle: 'Configuración',
        breadcrumb: [
          {
            text: 'Configuración',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },

    /* {
                  path: '/documentations',
                  name: 'documentationsView',
                  component: () => import('@/views/modules/DocumentationsView.vue'),
                  meta: {
                    pageTitle: 'Documentación',
                    breadcrumb: [
                      {
                        text: 'Documentación',
                        active: true,
                      },
                    ],
                    resource: 'Auth',
                  },
                }, */

    {
      path: '/states',
      name: 'statesView',
      component: () => import('@/views/modules/StatesView.vue'),
      meta: {
        pageTitle: 'Estados',
        breadcrumb: [
          {
            text: 'Estados',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },

    {
      path: '/orders',
      name: 'ordersView',
      component: () => import('@/views/modules/OrdersView.vue'),
      meta: {
        pageTitle: 'Ordenes',
        breadcrumb: [
          {
            text: 'Ordenes',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },
    {
      path: '/orders/:id',
      name: 'orderDetailsView',
      component: () => import('@/views/modules/OrderDetailsView.vue'),
      meta: {
        pageTitle: 'Ordenes',
        breadcrumb: [
          {
            text: 'Ordenes',
          },
          {
            text: 'Detalle de orden',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },

    {
      path: '/products',
      name: 'productsView',
      component: () => import('@/views/modules/ProductsView.vue'),
      meta: {
        pageTitle: 'Productos',
        breadcrumb: [
          {
            text: 'Productos',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },
    {
      path: '/create-product',
      name: 'createProductFormView',
      component: () => import('@/views/modules/ProductFormView.vue'),
      meta: {
        pageTitle: 'Crear producto',
        breadcrumb: [
          {
            text: 'Productos',
            to: '/products',
            active: false,
          },
          {
            text: 'Crear producto',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },
    {
      path: '/edit-product/:id',
      name: 'editProductFormView',
      component: () => import('@/views/modules/ProductFormView.vue'),
      meta: {
        pageTitle: 'Editar producto',
        breadcrumb: [
          {
            text: 'Productos',
            to: '/products',
            active: false,
          },
          {
            text: 'Editar producto',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },
    {
      path: '/product-statuses',
      name: 'productStatusesView',
      component: () => import('@/views/modules/ProductStatusesView.vue'),
      meta: {
        pageTitle: 'Estado de productos',
        breadcrumb: [
          {
            text: 'Estado de productos',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },

    {
      path: '/addresses',
      name: 'addressView',
      component: () => import('@/views/modules/AddressesView.vue'),
      meta: {
        pageTitle: 'Direcciones',
        breadcrumb: [
          {
            text: 'Direcciones',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },

    {
      path: '/campaigns',
      name: 'campaignsView',
      component: () => import('@/views/modules/CampaignsView.vue'),
      meta: {
        pageTitle: 'Campañas',
        breadcrumb: [
          {
            text: 'Campañas',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },
    {
      path: '/showrooms',
      name: 'showroomsView',
      component: () => import('@/views/modules/ShowroomsView.vue'),
      meta: {
        pageTitle: 'Showrooms',
        breadcrumb: [
          {
            text: 'Showrooms',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },

    {
      path: '/community-multimedia',
      name: 'communityMultimediaView',
      component: () => import('@/views/modules/CommunityMultimediaView.vue'),
      meta: {
        pageTitle: 'Comunidad',
        breadcrumb: [
          {
            text: 'Comunidad',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },

    {
      path: '/seller-multimedia',
      name: 'sellerMultimediaView',
      component: () => import('@/views/modules/SellerMultimediaView.vue'),
      meta: {
        pageTitle: 'Configuración slider home',
        breadcrumb: [
          {
            text: 'Configuración slider home',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },

    {
      path: '/faq-categories',
      name: 'faqCategoriesView',
      component: () => import('@/views/modules/FaqCategoriesView.vue'),
      meta: {
        pageTitle: 'Categorías de preguntas frecuentes',
        breadcrumb: [
          {
            text: 'Categorías de preguntas frecuentes',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },
    {
      path: '/categories',
      name: 'categoriesView',
      component: () => import('@/views/modules/CategoriesView.vue'),
      meta: {
        pageTitle: 'Categorías',
        breadcrumb: [
          {
            text: 'Categorías',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },
    {
      path: '/menu-config',
      name: 'menuConfigView',
      component: () => import('@/views/modules/MenuConfigView.vue'),
      meta: {
        pageTitle: 'Configuración del menú',
        breadcrumb: [
          {
            text: 'Configuración del menú',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },
    {
      path: '/menu-config/multimedia',
      name: 'menuMultimediaView',
      component: () => import('@/views/modules/MenuMultimediaView.vue'),
      meta: {
        pageTitle: 'Multimedia',
        breadcrumb: [
          {
            text: 'Configuración del menú',
            active: false,
          },
          {
            text: 'Multimedia',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },
    {
      path: '/tag-groups',
      name: 'tagGroupsView',
      component: () => import('@/views/modules/GroupTagsView.vue'),
      meta: {
        pageTitle: 'Grupo de etiquetas',
        breadcrumb: [
          {
            text: 'Grupo de etiquetas',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },
    {
      path: '/tags',
      name: 'tagsView',
      component: () => import('@/views/modules/TagsView.vue'),
      meta: {
        pageTitle: 'Etiquetas',
        breadcrumb: [
          {
            text: 'Etiquetas',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },
    {
      path: '/coupons',
      name: 'couponsView',
      component: () => import('@/views/modules/PromotionsView.vue'),
      meta: {
        pageTitle: 'Cupones',
        breadcrumb: [
          {
            text: 'Cupones',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },
    {
      path: '/coupons/create',
      name: 'couponsCreateFormView',
      component: () => import('@/views/modules/CouponFormView.vue'),
      meta: {
        pageTitle: 'Crear cupón',
        breadcrumb: [
          {
            text: 'Cupones',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },
    {
      path: '/coupons/edit/:id',
      name: 'couponsEditFormView',
      component: () => import('@/views/modules/CouponFormView.vue'),
      meta: {
        pageTitle: 'Editar cupón',
        breadcrumb: [
          {
            text: 'Cupones',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },

    {
      path: '/boxes',
      name: 'boxesView',
      component: () => import('@/views/modules/BoxesView.vue'),
      meta: {
        pageTitle: 'Configuración de cajas',
        breadcrumb: [
          {
            text: 'Configuración de cajas',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },

    /**
       * Blog routes
       */

    {
      path: '/blog-tags',
      name: 'blogTagsView',
      component: () => import('@/views/modules/BlogTagsView.vue'),
      meta: {
        pageTitle: 'Etiquetas de blog',
        breadcrumb: [
          {
            text: 'Etiquetas de blog',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },
    {
      path: '/blog-categories',
      name: 'blogCategoriesView',
      component: () => import('@/views/modules/BlogCategoriesView.vue'),
      meta: {
        pageTitle: 'Categorías de blog',
        breadcrumb: [
          {
            text: 'Categorías de blog',
            active: true,
          },
        ],
        resource: 'Auth',
      },
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
        resource: 'Auth',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

// Router Before Each hook for route protection
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    // ! We updated login route name here from `auth-login` to `login` in starter-kit
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser())
  }

  return next()
})

export default router
